<template>
	<m-main-layout>
		<el-form
			:model="formData"
			status-icon
			:rules="rules"
			ref="form"
			label-width="auto"
			class="margin-top"
			style="width:800px;"
		>
			<el-form-item label="H5 AgentId" prop="dd_h5_agent_id">
				<el-input v-model="formData.dd_h5_agent_id" style="width:300px;"></el-input>
			</el-form-item>
			<el-form-item label="H5 AppKey" prop="dd_h5_app_key">
				<el-input v-model="formData.dd_h5_app_key" style="width:300px;"></el-input>
			</el-form-item>
			<el-form-item label="H5 AppSecret" prop="dd_h5_app_secret">
				<el-input v-model="formData.dd_h5_app_secret" style="width:700px;"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('form')">确定</el-button>
				<el-button type="" @click="back">返回</el-button>
			</el-form-item>
		</el-form>
	</m-main-layout>
</template>

<script>
export default {
	name: "add",
	data() {
		return {
			formData: {
				dd_h5_agent_id: "",
				dd_h5_app_key: '',
				dd_h5_app_secret:''
			},
			rules: {
				dd_h5_agent_id: [{required: true, message: 'AgentId必填'}],
				dd_h5_app_key: [{required: true, message: 'AppKey必填'}],
				dd_h5_app_secret: [{required: true, message: 'AppSecret必填'}],
			}
		};
	},
	mounted() {
		this.getDetail();
	},
	methods: {
		back(){
			this.$user.removeTab();
			this.$router.back();
		},
		submitForm(formName) {
			let _this = this;
			console.log(_this.formData);
			this.$refs[formName].validate(valid => {
				if (valid) {
					_this.$http.request({
						url: "/SystemSetting/edit",
						datas: {..._this.formData},
						success: res => {
							if (res === "ok") {
								_this.$message.success("修改成功");
							} else {
								_this.$message.error("修改失败");
							}
						}
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		getDetail() {
			let _this = this;
			
			let _skeys = [];
			
			for (let k in this.formData) {
				_skeys.push(k);
			}
			
			_this.$http.request({
				url: "/SystemSetting/getSystem",
				datas: {skey: _skeys},
				success: res => {
					_this.formData = res;
				}
			});
		}
	}
};
</script>
